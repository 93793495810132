import { Box, CircularProgress, Typography } from "@mui/material"

export const LoadingOverlay = ({children, text}) => {

    return (
        <Box position="relative">
            <Box>
                {children}
            </Box>
            <Box position="absolute" style={{
                top: '40%', 
                transform: 'translateY(-50%)', 
                left: '50%', 
                transform: 'translateX(-50%)'
            }}>
                <Box textAlign="center">
                    <CircularProgress />
                    {text && <Typography component="div">{text}</Typography>}
                </Box>
            </Box>
        </Box>
    )

}