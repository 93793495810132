import { Button, IconButtonProps } from "@mui/material"
import ActionMenu from "components/ActionMenu"
import { useTranslation } from "next-i18next"
import { FunctionComponent, useState } from "react"
import { notNullNotUndefined } from "utils/objectUtils"
import { useRankingAnsweringContext } from "./useRankingAnsweringContextPairwise"
import { useRankingAnsweringStateContext } from "./useRankingAnsweringStateMaster"
import { QuestionHeadingVariant2Themeless, QuestionSubmitButton } from "../RankingLayoutComponents"
import { GuestIdentityEdit } from "modules/yoio/access/GuestIdentityEdit"

export const SelectButtonBigRound = (props) => {

    return (
        <Button
            {...props}
            color="inherit"
            sx={[
                {
                    backgroundColor: '#e50527 !important',
                    height: '70px',
                    padding: '20px 30px',
                    borderRadius: '50px',
                    transition: '0.3s',
                    color: '#fff',
                    fontSize: '1.3em',
                    fontWeight: 600,
                    maxWidth: '196px',
                    '&:hover': {
                        fontSize: '1.3em',
                    },
                },
                props.sx,
            ]}
        />
    )

}

export interface RateableItemActionMenuProps {
    itemId: string
    /**
     * If this component is inside a parent component that is clickable, you can use this to indicate that
     * the user is interacting with the menu.
     * 
     * Likewise you can use onInteractionEnd.
     */
    onInteractionStart?: () => void
    onInteractionEnd?: () => void
    toggleProps?: IconButtonProps
}

export const RateableItemActionMenu: FunctionComponent<RateableItemActionMenuProps> = ({itemId, onInteractionStart, onInteractionEnd, toggleProps}) => {
    notNullNotUndefined(itemId)

    const { t } = useTranslation('vsprio')

    //@ts-ignore
    const { addItemToBlacklist } = useRankingAnsweringContext()

    // Actions

    const handleBlacklistAddClick = () => {
        addItemToBlacklist(itemId)
    }

    const onMouseEnter = () => {
        onInteractionStart?.()
    }

    const onMouseLeave = () => {
        onInteractionEnd?.()
    }

    const menuItems = {
        actionsOften: [
            {
                title: t('vsprio:ranking.answering.rating.item.action.addToBlacklist.text'),
                handleClick: handleBlacklistAddClick,
            },
        ]
    }

    const popoverProps = {
        anchorOrigin:{ vertical: 'center', horizontal: 'center' },
        transformOrigin:{ vertical: 'top', horizontal: 'left' },
    }

    return <ActionMenu items={menuItems} PopoverProps={popoverProps} toggleProps={{size: 'small', ...toggleProps, onMouseEnter, onMouseLeave, }}></ActionMenu>

}

export const NameStep = () => {

    const { onNameStepFinished} = useRankingAnsweringStateContext()

    const [errors, setErrors] = useState(null) 

    const handleSubmit = () => {
        if (errors) {
            return;
        }
        onNameStepFinished()
    }

    const handleChanged = () => {
        setErrors(null)
    }

    const handleInvalid = () => {
        setErrors(['error'])
    }

    return (
        <>
            {/** @ts-ignore */}
            <QuestionHeadingVariant2Themeless>What&#39;s your name?</QuestionHeadingVariant2Themeless>
            {/** @ts-ignore */}
            <GuestIdentityEdit showLabel={false} textFieldProps={{fullWidth: true, size: 'small'}} onInvalid={handleInvalid} onChanged={handleChanged}/>
            {/** @ts-ignore */}
            <QuestionSubmitButton onClick={handleSubmit} disabled={errors?.length>0}/>
        </>
    )

}