import { Box } from "@mui/material"
import { forwardRef } from "react"

const LayoutCentered = forwardRef(({children, ...props}, ref) => {

    return (
        <Box display="flex" justifyContent="center" alignItems="center" style={{height: '100%'}} {...props} ref={ref}>
            <Box textAlign="center">
                {children}
            </Box>
        </Box>
    )

})

LayoutCentered.displayName = 'LayoutCentered'
export { LayoutCentered as LayoutCentered }