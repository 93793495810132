import { hideAppProgressIndicator, showAppProgressIndicator } from "modules/picasso-ui/main/AppProgressIndicator"
import { notifyWindowEvent } from "modules/picasso-ui/state/windowEventUtils"
import { changeAnsweringUser as apiChangeAnsweringUser, getBacklogRatingMySettings, getRatingItems, restartAnsweringMy } from "modules/plinzip/api/ratingApi"
import { hasGroupInEac, hasPermissionInEac } from "modules/react-auth/component/border/PermissionEntityBorder"
import { useRouter } from "next/router"
import { createContext, useContext, useEffect, useState } from "react"
import { useRoom } from "../../room/RoomContext"
import { RankingAnsweringEvent, RankingAnsweringMode, RankingAnsweringStep } from "./RankingAnsweringTypes"
import { useConfirmationDialog } from "components/dialog/confirm/ConfirmationDialog"
import { useTranslation } from "next-i18next"
import { useResolvedItemV2 } from "utils/useResolvedV2"
import { RankingAnsweringThemeProvider } from "./useRankingAnsweringTheme"
import { LoadingIndicatorFbStyle } from "modules/picasso-ui/present/status/LoadingIndicator"
import { Box } from "@mui/material"
import { isHavingValue, notNullNotUndefined } from "utils/objectUtils"
import { changeUserSettingsItemsBlacklistAdd, changeUserSettingsItemsBlacklistRemove } from "modules/plinzip/api/backlogApi"
import { useSWRConfig } from "swr"
import { useBacklogEacSwr, useBacklogIdContext } from "../BacklogContext"
import { useRef } from "react"
import { getCurrentTerminalKey, setCurrentTerminalKey } from "./currentTerminalKey"
import { NextParsedUrlQuery } from "next/dist/server/request-meta"
import { getAnsweringStateMyFetchKey, getRatingSettingMyFetchKey, useRankingAnsweringStateContext, useRankingAnsweringStateSettingsContext } from "./useRankingAnsweringStateMaster"

export const useRankingAnsweringApi = (backlogId) => {
    notNullNotUndefined(backlogId)

    const { mutate } = useSWRConfig()

    const refreshRatingSettingsMy = () => {
        return mutate(getRatingSettingMyFetchKey(backlogId))
    }

    const refreshAnsweringStateMy = () => {
        return mutate(getAnsweringStateMyFetchKey(backlogId, null))
    }

    return  {
        refreshRatingSettingsMy,
        refreshAnsweringStateMy,
    }
}

export interface RankingAnsweringUserInfo {
    userId?: string
    name?: string
}


export enum RankingAnsweringInitError {
    terminalModeOnlyAsGuest = 'terminalModeOnlyAsGuest'
}

export interface RankingAnsweringContextReturn {
    backlogId: string
    answeringInitError?: RankingAnsweringInitError
    step: RankingAnsweringStep
    /**
     * You can call this from the Step View Component to indicate that the step finished
     */
    onRatingFinished: () => void
    /**
     * You can call this from the Step View Component to indicate that the step finished
     */
    onDelimitStepFinished: () => void
    restart: () => Promise<any>
    hasPermissionRestart: boolean
    // eslint-disable-next-line no-unused-vars
    changeAnsweringUser: (backlogId: string, userId: string) => void
    user?: RankingAnsweringUserInfo
    // eslint-disable-next-line no-unused-vars
    addItemToBlacklist: (itemId: string) => void,
    // eslint-disable-next-line no-unused-vars
    removeItemFromBlacklist: (itemId: string, notify: boolean) => void,
    itemsBlacklistResolved: any
    ignoreRatingsMy?: boolean
    answeringMode: RankingAnsweringMode
}

const RankingAnsweringContext = createContext<RankingAnsweringContextReturn>({} as RankingAnsweringContextReturn)

export const useRankingAnsweringContext = () => {
    return useContext<RankingAnsweringContextReturn>(RankingAnsweringContext)
}

export interface RankingAnsweringPageQuery extends NextParsedUrlQuery {
    terminalKey?: string
}

/**
 *  at some point this should be completely obsolte. anything that happens must happen through "useRankingAnsweringStateContext"
 *  which works for any kind of ranking
 */
export const RankingAnsweringContextProvider = ({children}) => {

    const answeringStateSettings = useRankingAnsweringStateSettingsContext()
    const ignoreRatingsMy = answeringStateSettings?.ignoreRatingsMy === true
    const ignoreTerminalKey = answeringStateSettings?.ignoreTerminalKey === true

    const { t } = useTranslation('vsprio')

    const router = useRouter()

    const resultStale = useRef(false)

    const { backlogId } = useBacklogIdContext()

    //@ts-ignore
    const { ready, roomInfoResolved, refreshResult, ratingSettingsMyResolved } = useRoom()

    const { data: backlogEac, mutate: mutateEac } = useBacklogEacSwr(backlogId)

    const [answeringInitError, setAnsweringInitError] = useState<RankingAnsweringInitError>()

    const { answeringState, mutate: mutateAnsweringState, gotoAnsweringBegin, gotoStep } = useRankingAnsweringStateContext()

    const answeringMode = answeringState?.mode
    const step = answeringState?.step

/*     const userSettingsMyResolved = useResolvedItemV2(`backlogs/${backlogId}/ratingSettings/my/userSettings`, ()=>getBacklogRatingMySettings(backlogId).then((data)=>{
        return data.userSettings
    })) */

    //const ratingItemsResolved = useResolvedItemV2(`/backlogs/${backlogId}/ratings/1/items`, ()=>getRatingItems(backlogId), {revalidateOnFocus: false})

    const itemsBlacklistResolved = useResolvedItemV2(`backlogs/${backlogId}/ratingSettings/my/userSettings/blacklist`, ()=>{
        return Promise.all([getRatingItems(backlogId).then((res)=>res.data), getBacklogRatingMySettings(backlogId).then((data)=>data?.userSettings || null)]).then((values)=>{
            const [items, userSettings] = values

            if (userSettings === null || userSettings?.itemsBlacklist === null) {
                return []
            }

            if (!items || !userSettings?.itemsBlacklist) {
                return null;
            }
            return userSettings.itemsBlacklist.map(itemId=>{
                return items.find(i=>i.itemId===itemId)||null
            }).filter(i=>i!==null)
        }).catch((error)=>{
            // Nothing to do
            console.error(error)
        })

    }, {revalidateOnFocus: true})

    const { getConfirmation } = useConfirmationDialog()

    const hasPermissionRestart = backlogEac && hasPermissionInEac(backlogEac, 'backlogRateRestart')


    const user = backlogEac ? {
        userId: backlogEac.userId,
        name: backlogEac.userName,
    } : null

    // Functions

    const restart = async () => {
        const confirmed = await getConfirmation({
            title: t('vsprio:ranking.action.startOver.title')+'?',
            message: t('vsprio:ranking.action.startOver.consequence'),
        })

        if (!confirmed) {
            return;
        }

        showAppProgressIndicator()
        return restartAnsweringMy(backlogId).then(()=>{
            hideAppProgressIndicator()

            mutateAnsweringState().then(()=>{
                gotoAnsweringBegin()
            })

            refreshResult()
                
            notifyWindowEvent(RankingAnsweringEvent.rankingRatingValuesDeleted, {
                backlogId,
            })
        })
        .catch((e)=>{
            console.error(e)
            // User can retry
            hideAppProgressIndicator()
        })
    }

    // Actions 


    const addItemToBlacklist = (itemId: string) => {
        notNullNotUndefined(itemId)
        changeUserSettingsItemsBlacklistAdd(backlogId, backlogEac.userId, itemId).then(()=>{
            itemsBlacklistResolved.refresh()
        })
        .then(()=>{
            notifyWindowEvent(RankingAnsweringEvent.meUserSettingsItemsBlacklistChanged, {
                backlogId,
            })
        })
    }

    const removeItemFromBlacklist = (itemId, notify) => {
        notNullNotUndefined(itemId)
        changeUserSettingsItemsBlacklistRemove(backlogId, backlogEac.userId, itemId).then(()=>{
            itemsBlacklistResolved.refresh()
        })
        .then(()=>{
            if (notify !== false) {
                notifyWindowEvent(RankingAnsweringEvent.meUserSettingsItemsBlacklistChanged, {
                    backlogId,
                })
            }
        })
    }

    const changeAnsweringUser = (backlogId, userId) => {
        notNullNotUndefined(backlogId)

        if (userId === undefined) {
            throw new Error('userId cannot be undefined (null allowed)')
        }
        return apiChangeAnsweringUser(backlogId, userId).then(()=>{
            return mutateEac().then(()=>{
                return Promise.all([mutateAnsweringState, ratingSettingsMyResolved.refresh()])
            })
        })
    }

    // Step Transitions

    const onRatingFinished = () => {
        // Default: Stay in Rating View

        // If Delimit Step enabled, go on
        if (ratingSettingsMyResolved.item.delimitStep && answeringState?.finished.delimit !== true) {
            gotoStep(RankingAnsweringStep.delimit)
        } else {
            resultStale.current = true;
            gotoStep(RankingAnsweringStep.finished)
        }

    }

    const onDelimitStepFinished = () => {
        gotoStep(RankingAnsweringStep.finished)
        mutateAnsweringState()
        refreshResult()
    }

    // Events
    
    useEffect(()=>{
        if (!router.isReady) {
            return;
        }
        if (!backlogEac) {
            return null;
        }

        const query = router.query as RankingAnsweringPageQuery

        if (query.terminalKey && ignoreTerminalKey !== true && getCurrentTerminalKey() !== query.terminalKey) {
            if (hasGroupInEac(backlogEac, 'backlogOwner')) {
                setAnsweringInitError(RankingAnsweringInitError.terminalModeOnlyAsGuest)
                return;
            }

            setCurrentTerminalKey(query.terminalKey)
            Promise.all([ratingSettingsMyResolved.refresh(), roomInfoResolved.refresh()]).then(()=>{
                mutateAnsweringState()
            })
        }

    },[router.isReady, backlogEac])

    useEffect(()=>{
        if (!step) {
            return;
        }
        if (resultStale.current === true) {
            resultStale.current = false
            refreshResult()
        }
    }, [step])

    if (answeringInitError) {
        return (
            <Box display="flex" justifyContent="center" mt={3}>
                {answeringInitError === RankingAnsweringInitError.terminalModeOnlyAsGuest && <>Please open the terminal link in a browser in which you are not logged in or in an incognito tab.</>}
            </Box>
        )
    }


    const shouldRender = ready && router.isReady && isHavingValue(answeringState)


    if (!shouldRender) {
        if (!router.isReady) console.log('router.isReady', router.isReady)
        if (!ready) console.log('ready', ready)
        if (!isHavingValue(answeringState)) console.log('isHavingValue(answeringState)', answeringState)
        console.log('shouldRender', shouldRender)
    }

    if (!shouldRender) {
        return (
            <Box display="flex" justifyContent="center">
                <LoadingIndicatorFbStyle defer={1000}/>
            </Box>
        )
    }


    const value: RankingAnsweringContextReturn = {
        backlogId,
        answeringInitError,
        step,
        onRatingFinished,
        onDelimitStepFinished,
        restart,
        hasPermissionRestart,
        changeAnsweringUser,
        user,
        addItemToBlacklist,
        removeItemFromBlacklist,
        itemsBlacklistResolved,
        ignoreRatingsMy,
        answeringMode,
    }

    return (
        <RankingAnsweringContext.Provider value={value}>
                <RankingAnsweringThemeProvider>
                    {children}
                </RankingAnsweringThemeProvider>
        </RankingAnsweringContext.Provider>
    )

}