import { getAxiosDefault } from "apiClient/config/apiAxiosConfig"
import { notNullNotUndefined } from "utils/objectUtils"

export const getItemsOpen = (backlogId: string) => {
  notNullNotUndefined(backlogId)
  return getAxiosDefault().get(`/backlogs/${backlogId}/items/open`)
}

export const createReaction = (backlogId: string, itemId: string, value: string) => {
  notNullNotUndefined(backlogId)
  notNullNotUndefined(itemId)
  notNullNotUndefined(value)
  return getAxiosDefault().post(`/backlogs/${backlogId}/items/${itemId}/reactions`, {value})
}
export const deleteReaction = (backlogId: string, itemId: string, reactionId: string) => {
  notNullNotUndefined(backlogId)
  notNullNotUndefined(itemId)
  notNullNotUndefined(reactionId)
  return getAxiosDefault().delete(`/backlogs/${backlogId}/items/${itemId}/reactions/${reactionId}`)
}

export const getReactionsMy = (backlogId, itemId) => {
  notNullNotUndefined(backlogId)
  notNullNotUndefined(itemId)
  return getAxiosDefault().get(`/backlogs/${backlogId}/items/${itemId}/reactions/my`)
}