import { Box, Breakpoint, Button, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { CardCloseIconButton } from "modules/picasso-ui/present/CardCloseIconButton"
import Image from "next/image"
import { FC, PropsWithChildren, forwardRef } from "react"
import { useContainerQuery } from "react-container-query"
import { getClassNameByContainerQuery } from "utils/muiUtils"
import { orElse } from "utils/objectUtils"
import { useRankingAnsweringTheme } from "./answering/useRankingAnsweringTheme";

const useStyles = makeStyles((theme: Theme)=>({
    root: {
        marginBottom: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    'root-width-smaller-than-600': {
        marginBottom: theme.spacing(2.8),
        '& .questionHeading': {
            fontSize: '1.2rem',
        }
    },
    viewSectionHeading: {
        fontSize: '22px',
    },
    viewEmptyStateImageCont: {
        borderRadius: '45px',
        //backgroundColor: theme.palette.divider,
        display: 'inline-flex',
        alignItems: 'center',
        borderBottom: theme.spacing(1),
    },
    viewEmptyStateImage: {
        filter: 'opacity(0.4) hue-rotate(220deg)',
        marginLeft: '-10px !important' //image specific
    },
    //---
    setupHeading: {
        fontSize: '2.1em',
        fontWeight: 500,
    },
    setupPaper: {
        borderRadius: '4px',
        boxShadow: 'rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px',
        position: 'relative',
    },
    setupPaperHeading: {
        fontWeight: '600',
        fontSize: '1em',
        padding: `${theme.spacing(1.5)} ${theme.spacing(2.5)}`,
    },
    setupPaperContent: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        paddingBottom: theme.spacing(6),
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },
}))

const query = {
    'root-width-smaller-than-600': {
      maxWidth: 599,
    },
}

export const QuestionHeading = ({children, subtitle, style}) => {

    const classes = useStyles()

    //@ts-ignore
    const { answeringTheme } = useRankingAnsweringTheme()

    const [params, containerRef] = useContainerQuery(query, null)

    return (
        <div ref={containerRef} className={getClassNameByContainerQuery(classes, params, classes.root)} css={style}>
            <Typography variant="h3" className="questionHeading" sx={theme=>({
                color: theme.palette.text.primary,
                fontSize: '1.5rem',
                fontWeight: 600,
                lineHeight: '1.4',
                ...answeringTheme?.stepHeading,
            })}>
                {children}
            </Typography>
            {subtitle && 
                <Typography 
                    sx={theme=>({
                        marginTop: theme.spacing(1),
                        color: theme.palette.text.secondary,
                        fontSize: '16px',
                        ...answeringTheme?.stepHeadingSubtitle,
                    })}
                >{subtitle}</Typography>
            }
        </div>
    )

}

export const QuestionHeadingVariant2 = ({children, style}) => {

    //@ts-ignore
    const { answeringTheme } = useRankingAnsweringTheme()

    return <Typography variant="h3" fontSize="1.6em" sx={[{fontWeight: 400, ...answeringTheme?.stepHeading,...answeringTheme?.theme?.typography?.allHeadings}, style]}>{children}</Typography>
}

export const QuestionHeadingVariant2Themeless: FC<PropsWithChildren<{style?: any}>> = ({children, style}) => {
    return <Typography variant="h3"  mb={4} fontSize="1.6em" sx={[{fontWeight: 400}, style]}>{children}</Typography>
}

export const QuestionSubmitButton = ({children, ...props}) => {

    return (
        <Box mb={3} mt={1}>
            <Button variant="contained" color="primary" {...props}>{children || 'OK'}</Button>
        </Box>
    )

}

//@ts-ignore
export const ViewSectionHeading = forwardRef(({endContent,...props},ref) => {

    const classes = useStyles()

    if (endContent) {
        return (
            <Box mb={4} display="flex" alignItems="center">
                <Box flexGrow={1} display="flex" alignItems="center">
                    {/** @ts-ignore */}
                    <Typography {...props} className={classes.viewSectionHeading} ref={ref}/>
                </Box>
                {endContent}
            </Box>
        )
    }

    return (
        <Box mb={4}>
            {/** @ts-ignore */}
            <Typography {...props} className={classes.viewSectionHeading} ref={ref}/>
        </Box>
    )

})

export const ViewFieldHeading = ({endContent, mb,...props}) => {

    const classes = useStyles()

    return (
        <Box mb={orElse(mb,1)}>
            <Typography {...props} sx={{fontWeight: 500}} color="greyDark.main"/>
        </Box>
    )

}

export const ViewEmptyState = ({heading, info, imageSrc, endContent}) => {

    const classes = useStyles()

    return (
        <Box textAlign="center" pt={4} pb={10}>
            {imageSrc && 
                <div className={classes.viewEmptyStateImageCont}>
                    <Image src={imageSrc} width="90px" height="90px" unoptimized={true} className={classes.viewEmptyStateImage}/>
                </div>
            }
            {/** @ts-ignore */}
            <ViewSectionHeading style={{marginBottom: '12px'}}>{heading}</ViewSectionHeading>
            <Box color="text.secondary">
                <Typography>{info}</Typography>
            </Box>
            {endContent}
        </Box>
    )

}

export const SetupHeading = ({children}) => {

    const classes = useStyles()

    return (
        <Box mb={4}>
            <Typography component="h2" className={classes.setupHeading}>{children}</Typography>
        </Box>
    )

}

export const SetupPaperWithContents = ({title, children, onCloseClick}) => {

    const classes = useStyles()

    return (
        <SetupPaper>
            <Typography component="div" className={classes.setupPaperHeading}>{title}</Typography>
            <div className={classes.setupPaperContent}>{children}</div>
            {onCloseClick && <CardCloseIconButton onCloseClick={onCloseClick} />}
        </SetupPaper>
    )

}

export const SetupPaper = ({children}) => {

    const classes = useStyles()

    return (
        <div className={classes.setupPaper}>
            {children}
        </div>
    )

}

export const RankingCustomLogo = ({url, logoResponsive, logoResponsiveBreakpoint}:{url:string, logoResponsive?:boolean, logoResponsiveBreakpoint?:Breakpoint}) => {
    return <img src={url} width="200" height="50" 
        css={[
            { objectFit: 'contain', objectPosition: 'left', maxWidth: logoResponsive?'min(200px, max(10%, 100px))':undefined},
            logoResponsiveBreakpoint?theme=>({[theme.breakpoints.down(logoResponsiveBreakpoint)]:{ maxWidth: 'min(200px, max(10%, 100px))'}}):null
        ]} 
        />
}