import { useQueryParam } from "modules/picasso-modules/nextjs/commonUtils"
import { HtmlBodyStyles } from "modules/picasso-ui/layout/BackgroundGradient"
import { change, getDesignSettings, uploadAssetFromBase64 } from "modules/plinzip/api/backlogApi"
import { useErrorNotify } from "modules/yoio/errorsService"
import { FontLoader } from "modules/yoio/scaffold/FontLoader"
import { createContext, useContext } from "react"
import useSWR, { useSWRConfig } from "swr"
import { notNullNotUndefined, structuredCloneOrFallback } from "utils/objectUtils"
import { handleResponseDataGetContent } from "utils/useResolvedV2"
import { RankingDesignType } from "../backlog/rating/RankingTypes"
import { useBacklogIdContext } from "./BacklogContext"
import { AxiosResponse } from "axios"
import { SavedAsset } from "modules/picasso-ui/form/assets/AssetTypes"
import { hideAppProgressIndicator, showAppProgressIndicator } from "modules/picasso-ui/main/AppProgressIndicator"
import { UploadableAsset } from "modules/picasso-ui/form/assets/AssetsEdit"

export const RANKING_THEMES = {
    standard: {
        answeringSection: {
            backgroundColor: '#fff',
        }
    },
    gradientOrange: {
       answeringSection: {
            backgroundColor: 'transparent',
            background: 'linear-gradient(147deg,#f6b323 0%, #f23b26 74%)',
       },
       card: {
            backgroundColor: '#fff',
            borderRadius: '25px',
       }
    },
    gradientRedsmooth: {
        answeringSection: {
             paddingLeft: '14vw',
             paddingRight: '14vw',
             paddingTop: '10vw',
             paddingBottom: '10vw',
             position: 'relative',
             background: 'linear-gradient(to bottom right, #ff815e 0%, #e2076f 100%)',
        },
        stepHeading: {
            color: '#fff',
            fontWeight: 300,
            fontSize: '3.0em'
        },
        stepHeadingSubtitle: {
            color: 'rgb(255 255 255 / 0.62)',
            fontWeight: 500,
        },
        card: {
             backgroundColor: '#fff',
             borderRadius: '25px',
             title: {
                fontWeight: 400,
             }
        },
        choiceButton: {
            background: '#85c065!important',
            backgroundColor: '#85c065!important',
            height: '54px',
            padding: '13px 55px',
            fontSize: '19px',
        },
        choiceEqualButton: {
            style: {
                borderRadius: '50px',
                color: 'rgba(0, 0, 0, 0.6)',
                background: 'none',
                borderColor: 'rgba(0, 0, 0, 0.4)',
                paddingLeft: '30px',
                paddingRight: '30px',
            },
            size: 'medium',
        },
        buttonPrimaryStyle: {
            color: '#fff',
            borderRadius: '50px',
            backgroundColor: 'rgb(0 0 0 / 74%)',
            '&:hover': {
                color: '#fff',
                backgroundColor: 'rgb(0 0 0 / 54%)',
            },
            boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.3) 0px 8px 9px -7px',
        },
        buttonPrimaryOutlinedStyle: {
            color: 'rgb(0 0 0 / 74%)',
            borderRadius: '50px',
            backgroundColor: 'rgb(0 0 0 / 0%)',
            border: '1px solid rgb(0 0 0 / 74%)',
            '&:hover': {
                backgroundColor: 'rgb(0 0 0 / 0%)',
                border: '1px solid rgb(0 0 0 / 74%)',
            },
        },
        buttonSecondaryStyle: {
            color: '#fff',
            borderRadius: '50px',
            backgroundColor: 'rgb(255 255 255 / 13%)',
            '&:hover': {
                color: '#fff',
                backgroundColor: 'rgb(255 255 255 / 30%)',
            },
        }
     },
     gradientLightPurple: {
        answeringSection: {
             paddingLeft: '14vw',
             paddingRight: '14vw',
             paddingTop: '10vw',
             paddingBottom: '10vw',
             position: 'relative',
             background: 'linear-gradient(109deg, rgb(121 191 239) 21.3%, rgb(247 181 233) 65.4%)',
        },
        stepHeading: {
            color: '#fff',
            fontWeight: 300,
            fontSize: '3.0em'
        },
        card: {
             backgroundColor: '#fff',
             borderRadius: '25px',
             title: {
                fontWeight: 400,
             }
        },
        choiceButton: {
            background: '#85c065!important',
            backgroundColor: '#85c065!important',
            height: '54px',
            padding: '13px 55px',
            fontSize: '19px',
        },
        choiceEqualButton: {
            style: {
                borderRadius: '50px',
                color: 'rgba(0, 0, 0, 0.6)',
                background: 'none',
                borderColor: 'rgba(0, 0, 0, 0.4)',
                paddingLeft: '30px',
                paddingRight: '30px',
            },
            size: 'medium',
        },
        buttonPrimaryStyle: {
            color: '#fff',
            borderRadius: '50px',
            backgroundColor: 'rgb(0 0 0 / 74%)',
            '&:hover': {
                color: '#fff',
                backgroundColor: 'rgb(0 0 0 / 54%)',
            },
        },
        buttonPrimaryOutlinedStyle: {
            color: 'rgb(0 0 0 / 74%)',
            borderRadius: '50px',
            backgroundColor: 'rgb(0 0 0 / 0%)',
            border: '1px solid rgb(0 0 0 / 74%)',
            '&:hover': {
                backgroundColor: 'rgb(0 0 0 / 0%)',
                border: '1px solid rgb(0 0 0 / 74%)',
            },
        },
        buttonSecondaryStyle: {
            color: '#fff',
            borderRadius: '50px',
            backgroundColor: 'rgb(255 255 255 / 13%)',
            '&:hover': {
                color: '#fff',
                backgroundColor: 'rgb(255 255 255 / 30%)',
            },
        }
     },
     gradientBlueLight: {
        answeringSection: {
            backgroundColor: 'transparent',
            background: 'linear-gradient(to right, #4394ff 0%, #6f86d6 100%)',
            paddingLeft: '14vw',
            paddingRight: '14vw',
            paddingTop: '10vw',
            paddingBottom: '10vw',
        },
        stepHeading: {
            color: '#fff',
            fontWeight: 300,
            fontSize: '3.0em'
        },
        card: {
                backgroundColor: '#fff',
                borderRadius: '25px',
                title: {
                fontWeight: 400,
            }
        }
     }
}

export interface RankingDesign {
    body?: any,
    designType: RankingDesignType,
    rankingTheme: any,
    fontFamilies?: string[]
    logoAsset?: SavedAsset
}

export const getRankingDesignSettingsFetchKey = (backlogId) => {
    notNullNotUndefined(backlogId)
    return `/backlogs/${backlogId}/settings/designSettings`
}

export interface RankingDesignSettingsApi {
    changeDesignType: (backlogId: string, design: RankingDesignType) => Promise<any>
    uploadAssets: (assets: UploadableAsset[]) => Promise<AxiosResponse<SavedAsset>[]>
    handleAssetUploadSuccess: (uploads: AxiosResponse<SavedAsset>[]) => Promise<SavedAsset[]> 
    removeLogo: () => Promise<any>
}

export const useRankingDesignSettingsApi = (backlogId: string): RankingDesignSettingsApi => {
    notNullNotUndefined(backlogId)

    const { mutate } = useSWRConfig()

    const changeDesignType = (designType: RankingDesignType) => {
        notNullNotUndefined(designType)

        const updateFn = ()=>{
            return change(backlogId, [
                {
                    op: 'replace',
                    path: `designSettings/designType`,
                    value: designType
                }
            ])
        }

        const options = {
            populateCache: false, // Because API POST goes via backlog
            optimisticData: (current) => {
                return { 
                    ...current, 
                    designType
                }
            },
        }

        return mutate(getRankingDesignSettingsFetchKey(backlogId), updateFn, options)
    }

    const handleAssetUploadSuccess = (uploads: AxiosResponse<SavedAsset>[]):Promise<SavedAsset[]> => {
        const assets:SavedAsset[] = uploads.map((v)=>{
            return { ...v.data }
        });
        hideAppProgressIndicator()

        return mutate(getRankingDesignSettingsFetchKey(backlogId)).then(()=>{
            return assets
        })
        .catch((e)=>{
            console.error(e)
            hideAppProgressIndicator()
            throw e
        })
    } 

    const uploadAssets = (assets: UploadableAsset[]): Promise<AxiosResponse<SavedAsset>[]> => {

        showAppProgressIndicator('Uploading', 'linear')
        let uploads = assets.map((a, idx)=>{
            return uploadAssetFromBase64(backlogId, a.data_url, null)
        })
        return Promise.all(uploads).catch((e)=>{hideAppProgressIndicator(); throw e})
    }

    const removeLogo = () => {

        const updateFn = ()=>{
            return change(backlogId, [
                {
                    op: 'replace',
                    path: `designSettings/logoAssetId`,
                    value: null
                }
            ])
        }

        const options = {
            populateCache: false, // Because API POST goes via backlog
            optimisticData: (current) => {
                return { 
                    ...current, 
                    logoAsset: null
                }
            },
        }

        return mutate(getRankingDesignSettingsFetchKey(backlogId), updateFn, options)
    }


    return { changeDesignType, uploadAssets, handleAssetUploadSuccess, removeLogo }

}

export const useRankingDesignSettingsSWR = (backlogId: string) => {

    const swr = useSWR(backlogId ? getRankingDesignSettingsFetchKey(backlogId):null, ()=>getDesignSettings(backlogId, null, {
        supressErrorMessageIfAccessError: true,
    }).then(handleResponseDataGetContent))

    return swr

}

export const useRankingDesignLoader = (backlogId: string): RankingDesign => {

    const { notify } = useErrorNotify()

    const { data: designSettings } = useRankingDesignSettingsSWR(backlogId)
    
    // From Query Param
    const { value: rankingThemeKeyParam } = useQueryParam('theme')
    const { value: rankingDesignTypeParam } = useQueryParam('designType')
    
    const rankingDesignType = rankingDesignTypeParam || designSettings?.designType || RankingDesignType.standard
   
    const rankingThemeKeyEffective = rankingThemeKeyParam 
    || designSettings?.themeKey
    || (rankingDesignType === RankingDesignType.cozy ? 'gradientRedsmooth' : 'standard')


    const rankingTheme = RANKING_THEMES[rankingThemeKeyEffective] ? structuredCloneOrFallback(RANKING_THEMES[rankingThemeKeyEffective]) : {}


    if (rankingDesignType === RankingDesignType.cozy) {

        if (rankingTheme.answeringSection?.paddingLeft) delete rankingTheme.answeringSection.paddingLeft
        if (rankingTheme.answeringSection?.paddingRight) delete rankingTheme.answeringSection.paddingRight
        if (rankingTheme.answeringSection?.paddingTop) delete rankingTheme.answeringSection.paddingTop
        if (rankingTheme.answeringSection?.paddingBottom) delete rankingTheme.answeringSection.paddingBottom

        rankingTheme.body = rankingTheme.answeringSection

        if (rankingTheme.answeringSection) {
            delete rankingTheme.answeringSection
        }

/*         if (answeringTheme.answeringSection?.background) delete answeringTheme.answeringSection.background
        if (answeringTheme.answeringSection?.backgroundColor) delete answeringTheme.answeringSection.backgroundColor
        if (answeringTheme.answeringSection?.backgroundImage) delete answeringTheme.answeringSection.backgroundImage
        if (answeringTheme.answeringSection?.paddingLeft) delete answeringTheme.answeringSection.paddingLeft
        if (answeringTheme.answeringSection?.paddingRight) delete answeringTheme.answeringSection.paddingRight
        if (answeringTheme.answeringSection?.paddingTop) delete answeringTheme.answeringSection.paddingTop
        if (answeringTheme.answeringSection?.paddingBottom) delete answeringTheme.answeringSection.paddingBottom */

        if (!rankingTheme.theme?.typography?.allHeadings?.fontFamily) {
            rankingTheme.theme = rankingTheme.theme || {}
            rankingTheme.theme.typography = rankingTheme.theme.typography || {}
            rankingTheme.theme.typography.allHeadings = rankingTheme.theme.typography.allHeadings || {}
            rankingTheme.theme.typography.allHeadings.fontFamily = '"Dosis"'
            rankingTheme.theme.typography.allHeadings.fontWeight = '400'
        }
    }

    // Collect fonts to load
    let fontFamilies = null;
    try {
        if (rankingTheme?.theme) {
            if (rankingTheme.theme?.typography?.fontFamily) {
                fontFamilies = fontFamilies || []
                fontFamilies.push(rankingTheme.theme.typography.fontFamily)
            }
            if (rankingTheme.theme?.typography?.allHeadings?.fontFamily) {
                fontFamilies = fontFamilies || []
                fontFamilies.push(rankingTheme.theme.typography.allHeadings.fontFamily)
            }
        }
    } catch (error) {
        notify(error, null)
    }

    return {
        designType: rankingDesignType,
        rankingTheme,
        fontFamilies,
        logoAsset: designSettings?.logoAsset
    }
}

const RankingDesignContext = createContext<RankingDesign>(null)

export const useRankingDesign = () => {
    return useContext<RankingDesign>(RankingDesignContext)
}


export const RankingDesignProvider = ({children}) => {
    const { backlogId } = useBacklogIdContext()

    const rankingDesign = useRankingDesignLoader(backlogId)

    return <RankingDesignContext.Provider value={rankingDesign}>
        {children}
        {rankingDesign?.fontFamilies && <FontLoader fontFamilies={rankingDesign.fontFamilies}/>}
    </RankingDesignContext.Provider>
}

export const RankingDesignBodyStyles = () => {

    const rankingDesign = useRankingDesign()

    const { background, ...bodyStyles } = rankingDesign?.rankingTheme?.body || {}

    if (!background) {
        return null;
    }

    return <HtmlBodyStyles background={background} height="100%" fixed overflowHidden/>
    
}