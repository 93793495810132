import { LayoutCentered } from '../positioning/LayoutCentered';
import { Box, CircularProgress, LinearProgress } from '@mui/material';
import { LoadingOverlay } from 'modules/picasso-ui/present/content/LoadingOverlay';
import { BlurredContent } from 'modules/picasso-ui/present/effect/BlurredContent';
import { useEffect, useRef, useState } from 'react';
import { useIsMounted } from 'modules/picasso-ui/form/useIsMounted';

/**
 * 
 * @param {*} overlay 
 *          Make the loading border act as overlay - means you can still see the content inside it.
 * 
 * @param {*} blurred
 *          The content is blurred while loading. Only has effect when overlay=true.
 * @param {*} defer
 *      If given, then the indicator will only be shown after that time passed. 
 *      Use to avoid showing indicators when the content loads quickly.
 * @param {*} deferKey
 *      Only works if defer is given. Pass a unique key that identifies the loading process.
 *      Thereby the defer timer is reset, which avoids showing the indicator if the 'loading' params turns true for two consecutive independent load processes.
 * 
 * @returns 
 */
export const LoadingBorder = ({children, loading, variant, overlay, position, blurred, text, defer, deferKey}) => {

    variant = variant || 'circular'

    const [shouldDisplay, setShouldDisplay] = useState(!defer)

    const loadingState = useRef({}).current

    const isMounted = useIsMounted()

    useEffect(()=>{
        if (!defer) {
            return;
        }
        if (loading !== true) {
            if (shouldDisplay === true) {
                setShouldDisplay(false)
            }
            Object.keys(loadingState).forEach(key => delete loadingState[key]);
            return;
        }

        // Mark as loading
        if (deferKey) {
            loadingState[deferKey] = true
        }

        setTimeout(()=>{
            if (!isMounted()) {
                return;
            }

            if (deferKey && loadingState[deferKey] !== true) {
                // Return because it's not loading anymore. 
                //Just checking 'loading' is not sufficient here, because 'loading' could be true from a consecutive load process.
                return;
            }

            setShouldDisplay(true)
            
        }, defer)

    }, [loading])

    return (
        <>
            {loading && shouldDisplay ?
                (overlay ?
                    (blurred ?
                        <LoadingOverlay text={text}>
                            <BlurredContent>
                                {children}
                            </BlurredContent>
                        </LoadingOverlay>
                        :
                        <LoadingOverlay text={text}>{children}</LoadingOverlay>
                    )
                    :
                    <>
                        {
                            variant === 'linear' && <LinearProgress />
                        }
                        {   variant === 'circular' &&
                                (position === 'top' ?
                                    <Box mt={3} mb={3} textAlign="center">
                                        <CircularProgress />
                                    </Box>
                                    :
                                    <LayoutCentered>
                                        <CircularProgress />
                                    </LayoutCentered>
                                )
                        }
                    </>
                )
                :
                children   
            }
        </>
    );

};