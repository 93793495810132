import { css, keyframes } from "@emotion/react";
import { useIsMounted } from "modules/picasso-ui/form/useIsMounted";
import { useEffect, useState } from "react";

const loadingKeyframesLarge = keyframes`
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
`;

const loadingKeyframesSmall = keyframes`
    0% {
      top: 4px;
      height: 32px;
    }
    50%, 100% {
      top: 12px;
      height: 16px;
    }
`;

/**
 * @param {*} size
 *      'small', 'medium'
 * @param {*} defer
 *      Milliseconds.
 *      If given, then the indicator will only be shown after that time passed. 
 *      Use to avoid showing indicators when the content loads quickly.
 * @returns 
 */
export const LoadingIndicatorFbStyle = ({size, defer}) => {

    let keyframesEffective = null
    if (size === 'small') {
        keyframesEffective = loadingKeyframesSmall
    } else {
        keyframesEffective = loadingKeyframesLarge
    }

    const [render, setRender] = useState(!defer)

    const isMounted = useIsMounted()

    useEffect(()=>{
        if (!defer) {
            return;
        }

        setTimeout(()=>{
            if (!isMounted()) {
                return;
            }

            setRender(true)
        }, defer)

    }, [])

    if (!render) {
        return null;
    }

    if (size === 'small') {
        return (
            <div css={css`
                display: inline-block;
                position: relative;
                width: 48px;
                height: 30px;
                & div {
                    display: inline-block;
                    position: absolute;
                    left: 8px;
                    width: 8px;
                    background: #fff;
                    animation: ${keyframesEffective} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
                    background-color: rgb(125 168 220 / 20%);
                }
                & div:nth-of-type(1) {
                    left: 8px;
                    animation-delay: -0.24s;
                }
                & div:nth-of-type(2) {
                    left: 20px;
                    animation-delay: -0.12s;
                }
                & div:nth-of-type(3) {
                    left: 32px;
                    animation-delay: 0;
                }
            `}>
                            <div></div><div></div><div></div>
        </div>
        )
    }

    return (
        <div css={css`
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
            & div {
                display: inline-block;
                position: absolute;
                left: 8px;
                width: 16px;
                background: #fff;
                animation: ${keyframesEffective} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
                background-color: rgb(125 168 220 / 20%);
            }
            & div:nth-of-type(1) {
                left: 8px;
                animation-delay: -0.24s;
            }
            & div:nth-of-type(2) {
                left: 32px;
                animation-delay: -0.12s;
            }
            & div:nth-of-type(3) {
                left: 56px;
                animation-delay: 0;
            }
        `}>
            <div></div><div></div><div></div>
        </div>
    )

}

/**
 * @param {*} size
 *      'small', 'medium'
 * @param {*} defer
 *      If given, then the indicator will only be shown after that time passed. 
 *      Use to avoid showing indicators when the content loads quickly.
 * @returns 
 */
 export const LoadingIndicatorFbStyleDots = ({size, defer, color}) => {

    let keyframesEffective = null
    if (size === 'small') {
        keyframesEffective = loadingKeyframesSmall
    } else {
        keyframesEffective = loadingKeyframesLarge
    }

    const [render, setRender] = useState(!defer)

    const isMounted = useIsMounted()

    useEffect(()=>{
        if (!defer) {
            return;
        }

        setTimeout(()=>{
            if (!isMounted()) {
                return;
            }

            setRender(true)
        }, defer)

    }, [])

    if (!render) {
        return null;
    }

    if (size === 'small') {
        return (
            <div css={css`
                display: inline-block;
                position: relative;
                width: 48px;
                height: 30px;
                & div {
                    display: inline-block;
                    position: absolute;
                    left: 8px;
                    width: 8px;
                    background: #fff;
                    animation: ${keyframesEffective} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
                    background-color: rgb(125 168 220 / 20%);
                }
                & div:nth-of-type(1) {
                    left: 8px;
                    animation-delay: -0.24s;
                }
                & div:nth-of-type(2) {
                    left: 20px;
                    animation-delay: -0.12s;
                }
                & div:nth-of-type(3) {
                    left: 32px;
                    animation-delay: 0;
                }
            `}>
                            <div></div><div></div><div></div>
        </div>
        )
    }

    return (
        <div css={css`
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
            & div {
                display: inline-block;
                position: absolute;
                left: 8px;
                width: 16px;
                background: #fff;
                animation: ${keyframesEffective} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
                background-color: rgb(125 168 220 / 20%);
            }
            & div:nth-of-type(1) {
                left: 8px;
                animation-delay: -0.24s;
            }
            & div:nth-of-type(2) {
                left: 32px;
                animation-delay: -0.12s;
            }
            & div:nth-of-type(3) {
                left: 56px;
                animation-delay: 0;
            }
        `}>
            <div></div><div></div><div></div>
        </div>
    )

}