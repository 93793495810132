import { createTheme } from "@mui/material"
import { createThemeConfig } from "components/themes"
import { notify } from "modules/yoio/errorsService"
import { createContext, useContext } from "react"
import { RankingDesignType } from "../../backlog/rating/RankingTypes"
import { useRoom } from "../../room/RoomContext"
import { useBacklogIdContext } from "../BacklogContext"
import { useRankingDesign } from "../useRankingDesign"
import { OptionDesign, OptionImageSize } from "./RankingAnsweringTypes"

const RankingAnsweringTheme = createContext({})

export const useRankingAnsweringTheme = () => {
    return useContext(RankingAnsweringTheme)
}

export const RankingAnsweringThemeProvider = ({children}) => {
    
    // From Ranking settings
    const { ratingSettingsMyResolved } = useRoom() // Always resolved at this moment

    const { backlogId } = useBacklogIdContext()

    const rankingDesign = useRankingDesign(backlogId)

    const { designSettings } = ratingSettingsMyResolved.item

    const answeringTheme = rankingDesign.rankingTheme

    if (rankingDesign.designType === RankingDesignType.standard) {
        answeringTheme.theme = designSettings?.theme
    }

    // Build Mui theme from Ranking settings if necessary
    let theme = null
    try {
        if (answeringTheme?.theme) {
            const themeConfig = createThemeConfig(answeringTheme.theme)
            theme = createTheme(themeConfig)
        }

    } catch (error) {
        notify(error, null, 'error creating theme')
    }

    if (designSettings?.choiceButton) {
        answeringTheme.choiceButton = answeringTheme.choiceButton || {}
        answeringTheme.choiceButton = {
            ...answeringTheme.choiceButton,
            ...designSettings.choiceButton,
        }
    }

    return (
        <RankingAnsweringTheme.Provider value={{ 
            theme, 
            answeringTheme, 
            designSettings, 
            loading: !ratingSettingsMyResolved.item, 
            imagesLargeDuringRating: designSettings?.optionDesign === OptionDesign.imageCover && designSettings?.optionImageSize  === OptionImageSize.large,
        }}>
             {children}
        </RankingAnsweringTheme.Provider>
    )
}
