import { CircularProgress, TextField } from "@mui/material";
import { useSignedIn } from "modules/react-auth";
import { useGuestIdentityOld } from "modules/react-auth/useGuestIdentityOld";
import { useEffect, useState } from "react";
import { orElse } from "utils/objectUtils";

export const GuestIdentityEdit = ({onInvalid, onChanged, label, showLabel, fullWidth, textFieldProps}) => {

    const guestIdentityApi = useGuestIdentityOld()

    const [name, setName] = useState()

    const { me } = useSignedIn()

    useEffect(()=>{
      if (guestIdentityApi.getName()) {
        setName(guestIdentityApi.getName())
      } else {
        guestIdentityApi.createGuestIdentity(true).then(gi=>{
          if (gi.name) {
            setName(gi.name)
          }
        }).then(()=>{
          me.reloadMe()
        })
      }
    },[])

    const [errors, setErrors] = useState()
  
    showLabel = orElse(showLabel, true)

    const handleChange = (e) => {
      let name = e.target.value;

      if (name.length < 3 || name.length > 50) {
        setErrors(['name too short or too long'])

        if(onInvalid) {
          onInvalid()
        }
        return;
      } else {
        setErrors(null)
      }

      guestIdentityApi.setName(name)
      
      try {
        if(onChanged) {
          onChanged(name);
        }
      } catch (error) {
        console.error(error);
      }
    }
  
    if (!name) {
      return <><CircularProgress /></>
    }

    return (
      <>
        <TextField 
          label={showLabel ? (label || "Enter your name"):null}
          error={errors?.length > 0}
          variant="outlined" 
          defaultValue={name}
          onChange={handleChange}
          InputProps={{
            //className: classes.xxx,
          }}
          inputProps={{
            maxLength: 50
          }}
          InputLabelProps={{
            //className: classes.xxx,
          }}
          fullWidth={fullWidth}
          {...textFieldProps}
        />
      </>
    );
  
  }